define("Automatons/automatons/2011", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2011,
    type: "automatons",
    attributes: {
      name: "HM ES File Upload",
      description: "CMRGEN-19143",
      initialNode: "node::2022",
      style: "style::2004"
    }
  };
});