define("Automatons/nodes/2003", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2003,
    type: "nodes",
    attributes: {
      name: "HM File Upload Initial",
      type: "agent-pushed",
      fileUpload: {
        messages: {
          badFileError: "Sorry, the file type you sent may contain a virus. Please try another file.",
          serverError: "Sorry, we have trouble connecting to the server. Please try again another time.",
          sizeError: "Sorry, the file you sent was too large. Please choose a file smaller than 5mb.",
          typeError: "Sorry, the file type you sent is not supported. Please try again with a .jpg, .jpeg, .bmp, .gif, .png file type."
        }
      },
      onExit: function onExit() {
        if (api.fileUploadStatus === 'success') api.fireCustomEvent('FileUploadCompleted', {});
      },
      template: "template::2006",
      transitions: {
        fileUpload: [{
          condition: "api.fileUploadStatus === 'success'",
          target: "node::2010"
        }]
      }
    }
  };
});