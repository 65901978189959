define("Automatons/nodes/2013", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2013,
    type: "nodes",
    attributes: {
      name: "HM DK_PostChatSurvey - initial",
      type: "survey",
      formify: {
        fields: [{
          id: "question1",
          type: "radio",
          label: "1. Er det første gang, du har kontaktet os angående dette emne?",
          options: ["Ja", "Nej"]
        }, {
          id: "question2",
          type: "radio",
          label: "2. Fik du svar på dit spørgsmål?",
          options: ["Ja", "Nej"]
        }, {
          fields: [{
            id: "question3",
            type: "select",
            label: "3. Hvor tilfreds er du med vores kundeservice?",
            options: ["Meget utilfreds", "Utilfreds", "Hverken tilfreds eller utilfreds", "Tilfreds", "Meget tilfreds"],
            placeholder: "Vælg et svar"
          }, {
            id: "question3-reason-negative",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Meget utilfreds;Utilfreds;Hverken tilfreds eller utilfreds"
            },
            label: "Vi er meget kede af at høre, at vores service ikke levede op til dine forventninger. Vil du dele med os hvorfor?",
            placeholder: "Del venligst ikke personlig data, såsom e-mailadresse, telefonnummer eller kundenummer her."
          }, {
            id: "question3-reason-positive",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Tilfreds;Meget tilfreds"
            },
            label: "Vi er meget glade for at høre, at du er tilfreds med den service, du har fået. Vil du dele med os, hvad du var specielt glad for?",
            placeholder: "Del venligst ikke personlig data, såsom e-mailadresse, telefonnummer eller kundenummer her."
          }],
          group: ""
        }, {
          id: "question4",
          type: "radio",
          headingLeft: "Slet ikke sandsynligt",
          headingRight: "Meget sandsynligt",
          inline: true,
          label: "4. Hvor sandsynligt er det, at du vil anbefale H&M til en ven eller kollega?",
          note: "0=Slet ikke sandsynligt, 10=Meget sandsynligt",
          options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        }, {
          fields: [{
            id: "question5",
            type: "select",
            label: "5. Hvordan kan du bedst lide at kontakte os?",
            options: ["Telefon", "E-Mail", "Chat", "Sociale medier", "Direct Message", "Andet"],
            placeholder: "Vælg et svar"
          }, {
            id: "question5-other",
            type: "textarea",
            dynamic: {
              check: "question5",
              logic: "any",
              value: "Andet"
            },
            label: "angiv hvordan",
            placeholder: "Del venligst ikke personlig data, såsom e-mailadresse, telefonnummer eller kundenummer her."
          }],
          group: ""
        }],
        heading: "Hvordan var din oplevelse?",
        next: "submit",
        subheading: "Vi vil sætte pris på, hvis du vil udfylde dette korte spørgeskema. Din feedback hjælper os med at gøre din oplevelse bedre.",
        submit: "SEND"
      },
      template: "template::2001",
      transitions: {
        submit: {
          target: "node::2014"
        }
      }
    }
  };
});