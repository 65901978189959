define("Automatons/nodes/2022", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2022,
    type: "nodes",
    attributes: {
      name: "HM ES File Upload Initial",
      type: "agent-pushed",
      fileUpload: {
        messages: {
          badFileError: "Lo sentimos, el archivo que has enviado podría contener un virus. Por favor, inténtalo de nuevo con otro archivo.",
          serverError: "Lo sentimos, estamos teniendo problemas con la conexión al servidor. Por favor, inténtalo de nuevo en un momento.",
          sizeError: "Lo sentimos, el archivo que has enviado es demasiado grande. Por favor, elige un archivo menor a 5MB.",
          success: "El archivo se ha subido correctamente.",
          typeError: "Lo sentimos, el tipo de archivo que has enviado no es compatible. Por favor, inténtalo de nuevo con alguno de los siguientes formatos: .jpg, .jpeg, .bpm, .gif, .png, .doc, .docx o .pdf. "
        }
      },
      template: "template::2016",
      transitions: {
        fileUpload: [{
          condition: "api.fileUploadStatus === 'success'",
          target: "close"
        }]
      }
    }
  };
});