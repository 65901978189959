define("Automatons/automatons/2014", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2014,
    type: "automatons",
    attributes: {
      name: "HM DK File Upload",
      description: "CMRGEN-19147",
      initialNode: "node::2025",
      style: "style::2004"
    }
  };
});