define("Automatons/automatons/2018", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2018,
    type: "automatons",
    attributes: {
      name: "HM UK Cust Name AO OHOP Initial",
      description: "CMRGEN-21569",
      initialNode: "node::2028",
      style: "style::2003"
    }
  };
});