define("Automatons/automatons/2009", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2009,
    type: "automatons",
    attributes: {
      name: "Hm uk file upload",
      description: "CMRGEN-17089",
      initialNode: "node::2019",
      style: "style::2004"
    }
  };
});