define("Automatons/nodes/2005", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2005,
    type: "nodes",
    attributes: {
      name: "HM-SE_PostChatSurvey - initial",
      type: "survey",
      formify: {
        fields: [{
          id: "question1",
          type: "radio",
          label: "1. Var detta första gången du kontaktade oss angående detta specifika ärende?",
          options: ["Ja", "Nej"]
        }, {
          id: "question2",
          type: "radio",
          label: "2. Löste vi ditt ärende?",
          options: ["Ja", "Nej"]
        }, {
          fields: [{
            id: "question3",
            type: "select",
            label: "3. Hur nöjd är du med vår kundservice?",
            options: ["Inte alls nöjd", "Missnöjd", "Varken nöjd eller missnöjd", "Nöjd", "Mycket nöjd"],
            placeholder: "Välj ett alternativ"
          }, {
            id: "question3-reason-negative",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Inte alls nöjd;Missnöjd;Varken nöjd eller missnöjd"
            },
            label: "Vi är ledsna över att höra att vår service inte har uppfyllt dina förväntningar. Skulle du vilja berätta varför?",
            placeholder: "Lämna en kommentar"
          }, {
            id: "question3-reason-positive",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Nöjd;Mycket nöjd"
            },
            label: "Vi är glada att höra att du är nöjd med vår service. Vi vill gärna veta om det är något du är speciellt nöjd med?",
            placeholder: "Lämna en kommentar"
          }],
          group: ""
        }, {
          id: "question4",
          type: "radio",
          headingLeft: "Väldigt osannolikt",
          headingRight: "Väldigt sannolikt",
          inline: true,
          label: "4. Hur sannolikt är det att du skulle rekommendera H&M till en kollega eller vän?",
          note: "På en skala från 0 till 10 där 0 = Väldigt osannolikt och 10 = Väldigt sannolikt.",
          options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        }, {
          fields: [{
            id: "question5",
            type: "select",
            label: "5. Vilket sätt föredrar du att kontakta oss på?",
            options: ["Telefon", "E-post", "Chat", "Sociala medier", "Instant messaging", "Annat"],
            placeholder: "Välj ett alternativ"
          }, {
            id: "question5-other",
            type: "textarea",
            dynamic: {
              check: "question5",
              logic: "any",
              value: "Annat"
            },
            label: "Vänligen specificera",
            placeholder: "Lämna en kommentar"
          }],
          group: ""
        }],
        heading: "TACK FÖR ATT DU TAR DIG TID!",
        next: "submit",
        subheading: "Vi uppskattar att du tar dig tid att delta i en kort undersökning. Dina åsikter hjälper oss att förbättra vår service.",
        submit: "SKICKA"
      },
      template: "template::2001",
      transitions: {
        submit: {
          target: "node::2006"
        }
      }
    }
  };
});