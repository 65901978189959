define("Automatons/nodes/2024", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2024,
    type: "nodes",
    attributes: {
      name: "HM IN File Upload Initial",
      type: "agent-pushed",
      fileUpload: {
        messages: {
          badFileError: "Sorry, it seems there's a chance the file type you sent may contain a virus. Please try another file. ",
          serverError: "Sorry, we’re having trouble connecting to the server. Please try again in a little while.",
          sizeError: "Sorry, the file you sent us was too large. Try again with a file smaller than 5MB.",
          success: "The file was uploaded successfully.",
          typeError: "Sorry, the file type you sent is not supported. Please try again with one of these: .jpg, .jpeg, .bmp, .gif, .png, .doc, docx or .pdf file type."
        }
      },
      template: "template::2018",
      transitions: {
        fileUpload: [{
          condition: "api.fileUploadStatus === 'success'",
          target: "close"
        }]
      }
    }
  };
});