define("Automatons/nodes/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "nodes",
    attributes: {
      name: "HandM Post Chat Survey Thank You",
      type: "thankyou",
      isOutcomeNode: 1,
      outcomeMessage: "User has submitted postchat feedback.",
      template: "template::2002"
    }
  };
});