define("Automatons/nodes/2014", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2014,
    type: "nodes",
    attributes: {
      name: "HM DK_PostChatSurvey Thank You",
      type: "thankyou",
      headingText: "VI ØNSKER DIG EN GOD DAG",
      isOutcomeNode: 1,
      outcomeMessage: "User has submitted postchat feedback.",
      template: "template::2010",
      thankyouText: "Tak for at du tog dig tid til at hjælpe os med at forbedre H&M kundeservice."
    }
  };
});