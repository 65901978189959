define("Automatons/automatons/2006", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2006,
    type: "automatons",
    attributes: {
      name: "HM DK_PostChatSurvey",
      type: "satisfactionSurvey",
      description: "CMRASI-2853",
      initialNode: "node::2013",
      style: "style::2008"
    }
  };
});