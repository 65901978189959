define("Automatons/automatons/2004", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2004,
    type: "automatons",
    attributes: {
      name: "HM-SE_PostChatSurvey",
      type: "satisfactionSurvey",
      description: "CMRASI-1957",
      initialNode: "node::2005",
      style: "style::2005"
    }
  };
});