define("Automatons/nodes/2001", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2001,
    type: "nodes",
    attributes: {
      name: "HandM post chat survey - initial",
      type: "survey",
      formify: {
        fields: [{
          id: "question1",
          type: "radio",
          label: "1. Was this the first time you contacted us regarding this particular query?",
          options: ["Yes", "No"]
        }, {
          id: "question2",
          type: "radio",
          label: "2. Did we solve your query?",
          options: ["Yes", "No"]
        }, {
          fields: [{
            id: "question3",
            type: "select",
            label: "3. How satisfied would you say that you are with our customer service?",
            options: ["Not at all satisfied", "Dissatisfied", "Neither satisfied nor dissatisfied", "Satisfied", "Very satisfied"]
          }, {
            id: "question3-reason-negative",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Not at all satisfied;Dissatisfied;Neither satisfied nor dissatisfied"
            },
            label: "We are really sorry to hear that our service didn't meet your expectations. Would you care to tell us why?",
            placeholder: "Please do not share any personal data here, such as email address, phone or customer number."
          }, {
            id: "question3-reason-positive",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Satisfied;Very satisfied"
            },
            label: "We are thrilled to hear that you are satisfied with our service! We would love to hear what you were pleased with in particular, please tell us.",
            placeholder: "Please do not share any personal data here, such as email address, phone or customer number."
          }],
          group: ""
        }, {
          id: "question4",
          type: "radio",
          headingLeft: "Not at all likely",
          headingRight: "Extremely likely",
          inline: true,
          label: "4. How likely is it that you would recommend H&M to a friend or colleague?",
          note: "On a scale 0 to 10, where 0=Not at all likely and 10=Extremely likely.",
          options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        }, {
          fields: [{
            id: "question5",
            type: "select",
            label: "5. What is your preferred way to contact us?",
            options: ["Phone", "Email", "Chat", "Social media", "Instant messaging", "Other"]
          }, {
            id: "question5-other",
            type: "textarea",
            dynamic: {
              check: "question5",
              logic: "any",
              value: "Other"
            },
            label: "Please specify",
            placeholder: "Please do not share any personal data here, such as email address, phone or customer number."
          }],
          group: ""
        }],
        heading: "Thank you for your time!",
        next: "submit",
        subheading: "Please take a moment to complete the following brief survey. Your input will help us serve you better.",
        submit: "Send Comments"
      },
      template: "template::2001",
      transitions: {
        submit: {
          target: "node::2002"
        }
      }
    }
  };
});