define("Automatons/nodes/2021", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2021,
    type: "nodes",
    attributes: {
      name: "HM IT Post Chat Survey Thank You",
      type: "thankyou",
      headingText: "BUONA GIORNATA!",
      isOutcomeNode: 1,
      outcomeMessage: "User has submitted postchat feedback.",
      template: "template::2015",
      thankyouText: "Grazie per aiutarci a migliorare il Servizio Clienti H&M."
    }
  };
});