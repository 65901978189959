define("Automatons/automatons/2012", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2012,
    type: "automatons",
    attributes: {
      name: "HM IT File Upload",
      description: "CMRGEN-19144",
      initialNode: "node::2023",
      style: "style::2004"
    }
  };
});