define("Automatons/nodes/2028", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2028,
    type: "nodes",
    attributes: {
      name: "HM UK Cust Name AO Details OHOP",
      type: "chat",
      chatRouter: {
        checkAgentAvailability: false
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {

        var customerName = typeof api.automatonDataMap.CustomerName !== 'undefined' ? api.automatonDataMap.CustomerName : '';
        var marketLang = typeof api.automatonDataMap.MarketLang !== 'undefined' ? api.automatonDataMap.MarketLang : '';

        if (marketLang == 'UK') {
          if (customerName !== '') {
            var autoOpenerHM = "Hi " + customerName + "! I'm H&M's handy chatbot. The live agents aren't here just now, but I can quickly help with frequently asked questions. I can also provide contact details if I don't have the answer you're after. What can I assist you with today?";
          } else {
            var autoOpenerHM = "Hi! I'm H&M's handy chatbot. The live agents aren't here just now, but I can quickly help with frequently asked questions. I can also provide contact details if I don't have the answer you're after. What can I assist you with today?";
          }
        }

        api.engageChat({
          "agentGroup": typeof api.get("automatonConfigs") !== "undefined" ? api.get("automatonConfigs")["2011"].ag : 10006223,
          "businessUnit": typeof api.get("automatonConfigs") !== "undefined" ? api.get("automatonConfigs")["2011"].bu : 19001147,
          "qThreshold": typeof api.get("automatonConfigs") !== "undefined" ? api.get("automatonConfigs")["2011"].qt : 999,
          autoOpener: autoOpenerHM,
          deferChatRouting: true
        });
      }
    }
  };
});