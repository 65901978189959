define("Automatons/automatons/2007", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2007,
    type: "automatons",
    attributes: {
      name: "HM Spain_PostChatSurvey",
      type: "satisfactionSurvey",
      description: "CMRASI-2854",
      initialNode: "node::2015",
      style: "style::2008"
    }
  };
});