define("Automatons/nodes/2027", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2027,
    type: "nodes",
    attributes: {
      name: "HM UK Cust Name AO Details HOP",
      type: "chat",
      chatRouter: {
        checkAgentAvailability: false
      },
      isOutcomeNode: 1,
      onEntry: function onEntry() {

        var customerName = typeof api.automatonDataMap.CustomerName !== 'undefined' ? api.automatonDataMap.CustomerName : '';
        var marketLang = typeof api.automatonDataMap.MarketLang !== 'undefined' ? api.automatonDataMap.MarketLang : '';
        var ruleType = typeof api.automatonDataMap.RuleType !== 'undefined' ? api.automatonDataMap.RuleType : '';

        if (marketLang == 'UK') {
          if (customerName !== '') {
            var autoOpenerHM = "Hi " + customerName + "! I'm H&M's handy chatbot. I can help with finding a product and giving super-fast answers to frequently asked questions. What can I assist you with today?";
          } else {
            var autoOpenerHM = "Hi! I'm H&M's handy chatbot. I can help with finding a product and giving super-fast answers to frequently asked questions. What can I assist you with today?";
          }
        } else if (marketLang == 'ES') {
          if (customerName !== '') {
            var autoOpenerHM = "Bienvenido/a " + customerName + " a nuestro servicio de chat en vivo. ¿En qué puedo ayudarte?";
          } else {
            var autoOpenerHM = "Bienvenido/a a nuestro servicio de chat en vivo. ¿En qué puedo ayudarte?";
          }
        } else if (marketLang == 'IN') {
          if (customerName !== '') {
            var autoOpenerHM = "Welcome " + customerName + " to our live chat service. How can I help you today?";
          } else {
            var autoOpenerHM = "Welcome to our live chat service. How can I help you today?";
          }
        } else if (marketLang == 'IT') {
          if (customerName !== '') {
            var autoOpenerHM = "Benvenuto/a " + customerName + " al nostro servizio di assistenza via Chat. Come ti posso aiutare?";
          } else {
            var autoOpenerHM = "Benvenuto/a al nostro servizio di assistenza via Chat. Come ti posso aiutare?";
          }
        } else if (marketLang == 'DK') {
          if (ruleType == 'R') {
            if (customerName !== '') {
              var autoOpenerHM = "Velkommen " + customerName + " til vores Live chat. Hvad kan jeg hjælpe dig med i dag?";
            } else {
              var autoOpenerHM = "Velkommen til vores Live chat. Hvad kan jeg hjælpe dig med i dag?";
            }
          } else if (ruleType == 'P') {
            if (customerName !== '') {
              var autoOpenerHM = "Velkommen " + customerName + " til vores Live chat. Er der noget jeg kan hjælpe dig med?";
            } else {
              var autoOpenerHM = "Velkommen til vores Live chat. Er der noget jeg kan hjælpe dig med?";
            }
          }
        }

        api.engageChat({
          "agentGroup": typeof api.get("automatonConfigs") !== "undefined" ? api.get("automatonConfigs")["2011"].ag : 10006223,
          "businessUnit": typeof api.get("automatonConfigs") !== "undefined" ? api.get("automatonConfigs")["2011"].bu : 19001147,
          "qThreshold": typeof api.get("automatonConfigs") !== "undefined" ? api.get("automatonConfigs")["2011"].qt : 999,
          autoOpener: autoOpenerHM,
          deferChatRouting: true
        });
      }
    }
  };
});