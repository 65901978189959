define("Automatons/nodes/2015", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2015,
    type: "nodes",
    attributes: {
      name: "HM Spain_PostChatSurvey - initial",
      type: "survey",
      formify: {
        fields: [{
          id: "question1",
          type: "radio",
          label: "1. ¿Es la primera vez que nos contactas por esta consulta en particular?",
          options: ["Sí", "No"]
        }, {
          id: "question2",
          type: "radio",
          label: "2. ¿Hemos solucionado tu consulta?",
          options: ["Sí", "No"]
        }, {
          fields: [{
            id: "question3",
            type: "select",
            label: "3. ¿Cómo de satisfecho dirías que estás con nuestro servicio de Atención al Cliente?",
            options: ["Nada satisfecho(a)", "Insatisfecho(a)", "Ni satisfecho(a) ni insatisfecho(a)", "Satisfecho(a)", "Muy satisfecho(a)"],
            placeholder: "Selecciona una opción"
          }, {
            id: "question3-reason-negative",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Nada satisfecho(a);Insatisfecho(a);Ni satisfecho(a) ni insatisfecho(a)"
            },
            label: "Lamentamos mucho saber que nuestro servicio no ha cumplido tus expectativas. ¿Te importaría decirnos por qué?",
            placeholder: "Por favor, no compartas ningún dato personal, como dirección de correo electrónico, teléfono o número de cliente aquí."
          }, {
            id: "question3-reason-positive",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Satisfecho(a);Muy satisfecho(a)"
            },
            label: "Nos alegra saber que estás satisfecho/a con nuestro servicio. Nos encantaría saber qué es lo que más te ha gustado, ¿lo podrías compartir con nosotros?",
            placeholder: "Por favor, no compartas ningún dato personal, como dirección de correo electrónico, teléfono o número de cliente aquí."
          }],
          group: ""
        }, {
          id: "question4",
          type: "radio",
          headingLeft: "No es probable",
          headingRight: "Muy probable",
          inline: true,
          label: "4.¿Qué posibilidades hay de que recomiendes H&M a un amigo o colega?",
          note: "0=No es probable, 10=Muy probable",
          options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        }, {
          fields: [{
            id: "question5",
            type: "select",
            label: "5. ¿Cuál es tu forma preferida de contactarnos?",
            options: ["Teléfono", "Email", "Chat", "Social media", "Mensajería instantánea", "Otro"],
            placeholder: "Selecciona una opción"
          }, {
            id: "question5-other",
            type: "textarea",
            dynamic: {
              check: "question5",
              logic: "any",
              value: "Otro"
            },
            label: "Por favor especifica cual:",
            placeholder: "Por favor, no compartas ningún dato personal, como dirección de correo electrónico, teléfono o número de cliente aquí."
          }],
          group: ""
        }],
        heading: "¡GRACIAS POR DEDICARNOS UNOS MINUTOS!",
        next: "submit",
        subheading: "Te pedimos un momento para completar esta breve encuesta. Tu opinión nos ayudará a ofrecerte un mejor servicio.",
        submit: "ENVIAR RESPUESTA"
      },
      surveyAlerts: [{
        name: "HM-Spain_PostChatSurvey - Dissatisfied Alert",
        conditions: {
          or: ["question3 equals Nada satisfecho(a)", "question3 equals Insatisfecho(a)"]
        },
        emailSpecName: "HM-ES-Dissatisfied_SurveyAlert",
        emailSubject: "H&M - Survey Response Alert for [agent-id]",
        emailTemplate: "template::2014"
      }],
      template: "template::2001",
      transitions: {
        submit: {
          target: "node::2016"
        }
      }
    }
  };
});