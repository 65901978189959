define("Automatons/nodes/2025", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2025,
    type: "nodes",
    attributes: {
      name: "HM DK File Upload Initial",
      type: "agent-pushed",
      fileUpload: {
        messages: {
          badFileError: "Det lader til, at filen du vil tilføje, måske kan indeholde virus. Forsøg venligst med en anden fil.",
          serverError: "Vi kan ikke få forbindelse til serveren i øjeblikket. Prøv venligst igen senere.",
          sizeError: "Filen du forsøgte at tilføje, er for stor. Forsøg igen med en fil, der er mindre end 5 MB.",
          success: "Filen er tilføjet.",
          typeError: "Vi understøtter ikke det valgte filformat. Forsøg igen med et af følgende formater: .jpg, .jpeg, .bmp, .gif, .png, .doc, docx eller .pdf."
        }
      },
      template: "template::2019",
      transitions: {
        fileUpload: [{
          condition: "api.fileUploadStatus === 'success'",
          target: "close"
        }]
      }
    }
  };
});