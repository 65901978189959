define("Automatons/automatons/2013", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2013,
    type: "automatons",
    attributes: {
      name: "HM IN File Upload",
      description: "CMRGEN-19146",
      initialNode: "node::2024",
      style: "style::2004"
    }
  };
});