define("Automatons/nodes/2007", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2007,
    type: "nodes",
    attributes: {
      name: "HM-US_PostChatSurvey Thank You",
      type: "thankyou",
      isOutcomeNode: 1,
      outcomeMessage: "User has submitted postchat feedback.",
      template: "template::2008"
    }
  };
});