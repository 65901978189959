define("Automatons/nodes/2023", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2023,
    type: "nodes",
    attributes: {
      name: "HM IT File Upload Initial",
      type: "agent-pushed",
      fileUpload: {
        messages: {
          badFileError: "Ci dispiace, il documento che ci hai inviato potrebbe contenere un virus. Per favore, riprova con un altro documento.",
          serverError: "Ci dispiace, stiamo riscontrando dei problemi di connessione con il server. Per favore, riprova tra qualche istante.",
          sizeError: "Ci dispiace, il documento che ci hai inviato è troppo grande. Per favore, scegli un documento che non superi i 5MB",
          success: "Il documento è stato caricato con successo",
          typeError: "Ci dispiace, il tipo di documento che ci hai inviato non è compatibile. Per favore, riprova scegliendo uno dei seguenti formati: .jpg, .jpeg, .bpm, .gif, .png, .doc, .docx o .pdf."
        }
      },
      template: "template::2017",
      transitions: {
        fileUpload: [{
          condition: "api.fileUploadStatus === 'success'",
          target: "close"
        }]
      }
    }
  };
});