define("Automatons/automatons/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "automatons",
    attributes: {
      name: "HM File Upload Form",
      description: "CMRASI-1780 CMRASI-2400 CMRASI-2417",
      initialNode: "node::2003",
      style: "style::2004"
    }
  };
});