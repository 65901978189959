define("Automatons/nodes/2020", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2020,
    type: "nodes",
    attributes: {
      name: "HM IT post chat survey - initial",
      type: "survey",
      formify: {
        fields: [{
          id: "question1",
          type: "radio",
          label: "1. È la prima volta che ci contatti per questo motivo?",
          options: ["Sì", "No"]
        }, {
          id: "question2",
          type: "radio",
          label: "2. Abbiamo risolto il tuo dubbio?",
          options: ["Sì", "No"]
        }, {
          fields: [{
            id: "question3",
            type: "select",
            label: "3. Quanto ti ritieni soddisfatto del nostro servizio clienti?",
            options: ["Per nulla soddisfatto", "Insoddisfatto", "Né soddisfatto né insoddisfatto", "Soddisfatto", "Molto soddisfatto"],
            placeholder: "Seleziona un’opzione"
          }, {
            id: "question3-reason-negative",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Per nulla soddisfatto;Insoddisfatto;Né soddisfatto né insoddisfatto"
            },
            label: "Siamo davvero spiacenti che il nostro servizio non abbia soddisfatto le tue aspettative. Potresti dirci il perché?",
            placeholder: "Per favore non condividere qui dati personali come la tua email, cellulare o Numero Cliente."
          }, {
            id: "question3-reason-positive",
            type: "textarea",
            dynamic: {
              check: "question3",
              logic: "any",
              value: "Soddisfatto;Molto soddisfatto"
            },
            label: "Siamo davvero contenti che il nostro servizio sia stato di tuo gradimento! Ci farebbe molto piacere sapere l’aspetto che ti ha soddisfatto di più.",
            placeholder: "Per favore non condividere qui dati personali come la tua email, cellulare o Numero Cliente."
          }],
          group: ""
        }, {
          id: "question4",
          type: "radio",
          headingLeft: "Per nulla probabile",
          headingRight: "Estremamente probabile",
          inline: true,
          label: "4. Con quale probabilità consiglieresti H&M a un amico o collega?",
          note: "0=Per nulla probabile, 10=Estremamente probabile",
          options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        }, {
          fields: [{
            id: "question5",
            type: "select",
            label: "5. Qual è il tuo canale preferito per contattarci?",
            options: ["Telefono", "Email", "Chat", "Social media", "Messaggio istantaneo", "Altro"],
            placeholder: "Seleziona un’opzione"
          }, {
            id: "question5-other",
            type: "textarea",
            dynamic: {
              check: "question5",
              logic: "any",
              value: "Altro"
            },
            label: "Per favore, specifica",
            placeholder: "Per favore non condividere qui dati personali come la tua email, cellulare o Numero Cliente."
          }],
          group: ""
        }],
        heading: "GRAZIE PER IL TUO TEMPO!",
        next: "submit",
        subheading: "Ti chiediamo solo un momento per compilare questo breve sondaggio. La tua opinione ci aiuterà a offrirti un miglior Servizio Clienti.",
        submit: "INVIA"
      },
      template: "template::2001",
      transitions: {
        submit: {
          target: "node::2021"
        }
      }
    }
  };
});