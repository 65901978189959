define("Automatons/nodes/2018", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2018,
    type: "nodes",
    attributes: {
      name: "HandM IN Post Chat Survey Thank You",
      type: "thankyou",
      headingText: "HAVE A WONDERFUL DAY!",
      isOutcomeNode: 1,
      outcomeMessage: "User has submitted postchat feedback.",
      template: "template::2012",
      thankyouText: "Thank you for helping us improve H&M Customer Service."
    }
  };
});